<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col>
            <v-card>
              <v-toolbar flat>
                <v-icon>mdi-bell-ring-outline</v-icon>
                <v-toolbar-title class="ml-3">{{
                  $t("model.line_notify.line_notify")
                }}</v-toolbar-title>
              </v-toolbar>

              <validation-observer ref="form" v-slot="{ handleSubmit, reset }">
                <form
                  @submit.prevent="handleSubmit(onSave)"
                  @reset.prevent="reset"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('model.permission.description')"
                          rules="required|max:1000"
                        >
                          <v-textarea
                            v-model="description"
                            outlined
                            prepend-icon="mdi-message-draw"
                            :error-messages="errors"
                            :placeholder="$t('base.description')"
                            :label="$t('base.description')"
                            counter="1000"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <v-subheader>{{ $t("base.imgChoose") }}</v-subheader>
                        <v-file-input
                          v-model="selectFiles"
                          multiple
                          prepend-icon="mdi-image-multiple"
                          show-size
                          accept="image/*"
                          :label="$t('base.imgChoose')"
                          :disabled="processing"
                        >
                          <template #selection="{ text }">
                            <v-chip small label color="primary">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-divider />
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-subheader>{{
                          $t("model.edr_colleage.edr_colleage")
                        }}</v-subheader>

                        <v-text-field
                          v-model="searchTxt"
                          :label="$t('base.search')"
                          filled
                          rounded
                          dense
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle"
                        />

                        <v-card-text
                          style="max-height: 400px"
                          class="overflow-y-auto"
                        >
                          <v-treeview
                            v-model="selectedColleges"
                            :items="collegeList"
                            :search="searchTxt"
                            :filter="filter"
                            item-key="id"
                            item-text="name_th"
                            :selection-type="'leaf'"
                            selectable
                            :return-object="false"
                            open-all
                            hoverable
                          />
                        </v-card-text>
                      </v-col>

                      <v-divider vertical />

                      <v-col cols="12" md="6">
                        <v-card-text
                          style="max-height: 400px"
                          class="overflow-y-auto"
                        >
                          <div
                            v-if="selectedColleges.length === 0"
                            key="title"
                            class="title font-weight-light grey--text pa-4 text-center"
                          >
                            Please select college.
                          </div>

                          <v-scroll-x-transition group hide-on-leave>
                            <v-chip
                              v-for="(id, index) in selectedColleges"
                              :key="`role-form-permission-selected-${index}`"
                              class="ma-2"
                              close
                              @click:close="removeItem(index)"
                            >
                              {{ findDisplayById(id) }}
                            </v-chip>
                          </v-scroll-x-transition>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-checkbox
                      v-model="selectAll"
                      class="mr-2"
                      :label="$t('base.selectAll')"
                      @change="onSelectAllCheange"
                    />
                    <v-btn text @click="selectedColleges = []">
                      <v-icon>mdi-delete</v-icon>
                      {{ $t("base.clear") }}
                    </v-btn>
                  </v-card-actions>

                  <v-divider />

                  <v-col cols="12" class="mt-6" align="center">
                    <v-btn color="primary" type="submit" :disabled="processing">
                      <v-icon class="mr-2">mdi-send-outline</v-icon>
                      {{ $t("base.okay") }}
                    </v-btn>
                  </v-col>
                </form>
              </validation-observer>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <wee-confirm ref="weeConfirmRef" />
    <wee-toast ref="weeToastRef" />
    <base-wee-loader v-model="processing" />
  </v-container>
</template>

<script>
// service
import EdrColleageService from "@/api/EdrColleageService";
import DevService from "@/api/DevService";
import {
  onBeforeUnmount,
  ref,
  onMounted,
  computed,
  reactive,
  toRefs
} from "@vue/composition-api";
import useBase from "@/composition/UseBase";
export default {
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast")
  },
  setup(props, { refs, root }) {
    const service = new EdrColleageService();
    const devService = new DevService();
    const selectAll = ref(true);
    const collegeList = ref([]);
    const processing = ref(false);
    const { $i18n } = root;
    const { toast } = useBase(refs, root);

    const searchTxt = ref("");
    const selectedColleges = ref([]);
    const entity = reactive({
      description: "",
      selectFiles: []
    });
    onMounted(() => {
      loadColege();
    });
    const loadColege = async () => {
      processing.value = true;
      const { response, error } = await service.findAllProductionSite();
      processing.value = false;
      if (error) {
        toast(false, error);
        return;
      }
      toast(response.status, response.message);
      if (response && response.dataList) {
        collegeList.value = response.dataList;
        selectedColleges.value = response.dataList.map(item => {
          return item.id;
        });
      }
    };
    const findDisplayById = id => {
      const item = collegeList.value.find(p => p.id == id);
      return item ? item.name_th : "-";
    };
    const caseSensitive = ref(false);
    const filter = computed(() => {
      return caseSensitive.value
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    });
    const removeItem = index => {
      selectedColleges.splice(index, 1);
    };
    const onSelectAllCheange = () => {
      if (!selectAll.value) {
        selectedColleges.value = [];
      } else {
        selectedColleges.value = collegeList.value.map(item => {
          return item.id;
        });
      }
    };
    const onSave = async () => {
      let formData = new FormData();
      formData.append("description", entity.description);
      formData.append("filesLenght", entity.selectFiles.length);
      if (entity.selectFiles.length > 0) {
        for (let index = 0; index < entity.selectFiles.length; index++) {
          const element = entity.selectFiles[index];
          formData.append("file_" + index, element);
        }
      }
      processing.value = true;
      for (const collegeId of selectedColleges.value) {
        await sendNotify(collegeId, formData);
      }
      processing.value = false;
      toast(true, $i18n.tc("success.success"));
    };
    const getCollgeById = id => collegeList.value.find(p => p.id == id);
    
    const sendNotify = async (collegeId, data) => {
      const college = getCollgeById(collegeId);
      if (!college) {
        return;
      }
      await devService.sendLineNotifyToEdr(data, college);
      return new Promise(resolve => {
        resolve(true);
      });
    };

    onBeforeUnmount(() => {});

    return {
      processing,
      selectedColleges,
      collegeList,
      searchTxt,
      filter,
      findDisplayById,
      removeItem,
      selectAll,
      onSelectAllCheange,
      onSave,
      ...toRefs(entity)
    };
  }
};
</script>
